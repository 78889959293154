import { useContext } from "react";
import {MainContext} from "../../Context/Context";
import { Plus, Less, ButtonsContainer } from "./AccesibilityStyle";

const AccesibilityFont = () => {

    const size = useContext(MainContext);
    const {fontSizeUp, fontSizeDown} = size;
    console.log(size)


    return <ButtonsContainer>
            <Plus onClick={fontSizeUp}>A+</Plus>
            <Less onClick={fontSizeDown}>A-</Less>
        </ButtonsContainer>
}

export default AccesibilityFont;