import { CloseButton, Background, Image } from "./BigImageStyle";
import { createPortal } from "react-dom";
const zone = document.getElementById("image")

const BigImage = ({src, onClick}) => {

    return createPortal(<Background onClick={onClick}>
        <Image src={src} />
        <CloseButton>Tap para cerrar</CloseButton>
    </Background>, zone)
}

export default BigImage;