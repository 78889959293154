import styled from "styled-components";

const HeaderContainer = styled.header`
    padding: 15px;
    background-color: #060606;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const HeaderLogo = styled.img`
    width: 120px;
    max-width: 120px;
`

const HeaderTitle = styled.h1`
    font-size: 18px;
    font-weight: 500;
`

export {
    HeaderContainer,
    HeaderLogo,
    HeaderTitle
}