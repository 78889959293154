import styled from 'styled-components';

const Button = styled.a`
    background-color: #242424;
    text-decoration: none;
    font-size: 20px;
    color: #ccc;
    font-size: 16px;
    display: block;
    width: max-content;
    flex-shrink: 0;
    padding: 10px 20px;
    border-radius: 20px;

    &.active{
        background-color: blue;
    }
`

const NavContainer = styled.nav`
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    overflow: scroll;
    padding: 10px;
    background-color: #040404;
    position: sticky;
    top:0;

    &::-webkit-scrollbar{
        display: none;
    }
`

export {
    Button,
    NavContainer
}

