import { createContext, useState } from "react";

const MainContext = createContext({});

const ContextProvider = ({children}) => {
    const [fontSize, setFontSize] = useState(100)


    const fontSizeUp = () => {
        if(fontSize >= 175) return;
        setFontSize(fontSize + 25)
    }
    
    const fontSizeDown = () => {
        if(fontSize <= 75) return;
        setFontSize(fontSize -25);
    }

    return <MainContext.Provider value={{fontSize, fontSizeUp, fontSizeDown}}>
        {
            children
        }
    </MainContext.Provider>

}


export {
    MainContext,
    ContextProvider
};
