import { BodyCard, TitleCard, ImageCard, DescriptionCard, VariantsInCard, VariantsContainer } from "./CardStyle";
import { MainContext } from "../../Context/Context";
import { useContext, useState } from "react";
import BigImage from "../BigImage";

const Card = ({title, description, image, variants, price}) => {

    const [status, setStatus] = useState(false);

    const statusHandler = () => {
        setStatus(!status)
    }

    const size = useContext(MainContext);
    const {fontSize} = size;
    console.log(fontSize)

    const descriptionStyle = {
        "fontSize": `${fontSize*.875}%`,
    }

    const headingStyle = {
        "fontSize": `${fontSize*1.375}%`,
    }

    const priceStyle = {
        "fontSize": `${fontSize*.8125}%`,
    }

    return (
        <BodyCard>
            <div>
                <TitleCard style={headingStyle}>{title}</TitleCard>
                <DescriptionCard style={descriptionStyle}>{description}</DescriptionCard>
                {
                    variants && <VariantsContainer>
                        {
                            variants.map((el, index) => {
                            return <VariantsInCard 
                            key={`${el.name}_${index}`} style={priceStyle}>
                                {el.name} ${el.price}
                            </VariantsInCard>
                            })
                        }
                    </VariantsContainer> 
                }
                {
                    !variants && <VariantsInCard style={priceStyle}>${price}</VariantsInCard>
                }
            </div>
            <div>
                {
                 image && <ImageCard src={image} onClick={statusHandler} alt={title? title : "avocado roll"} />
                }
               
            </div>
            {
                status && <BigImage src={image} onClick={statusHandler}/>
            }
        </BodyCard>
    )
}

export default Card;