
import {Button, NavContainer} from "./NavigatorStyles";


const Navigator = ({items}) => {



    const active = null;

    return <NavContainer>
        {
             items.map((el, index) => {
                return <Button 
                key={`${el}_${index}`}
                href={`#${el.split(" ").join("-")}`}
                >{el}</Button>
            })
        }
    </NavContainer>
    
   
}

export default Navigator;