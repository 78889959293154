import styled from "styled-components";

const Plus = styled.button`
    font-size: 20px;
    background-color: #333;
    color: #ddd;
    border-radius: 100px 0 0 100px;
    width: 50px;
    height: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Less = styled.button`
    font-size: 14px;
    background-color: #333;
    color: #ddd;
    border-radius: 0 100px 100px 0;
    width: 50px;
    height: 50px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonsContainer = styled.div`
    display: flex;
    gap: 2px;
`

export {
    Plus,
    Less,
    ButtonsContainer
}