import styled from "styled-components";

const SectionHeading = styled.h2`
    padding-top: 20px;
    margin: 0;
    font-size: 32px;
    padding-left: 10px;
    padding-bottom: 15px;
    font-weight: normal;
    color: #ddd;
`

export {
    SectionHeading
}