import Main from './main'
import data from "./__mock__/data.json";
import Card from "./components/Card";
import Header from "./components/Header"
import Navigator from "./components/Navigator";
import SectionTitle from "./components/SectionTitle";
import {ContextProvider, MainContext} from './Context/Context';
import { useContext } from 'react';


const navIndex = Object.keys(data.dishes);

const App = () => {

    const size = useContext(MainContext);
    const {fontSize} = size;


    return (
        <ContextProvider>
            <main>
                <Main fontSize={fontSize}/>
                <Header />
                <Navigator items={navIndex}/>
                <SectionTitle name={navIndex[0]}/>
                <section className="section-grid">
                    {
                        data.dishes.Entradas.elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes.Entradas.name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[1]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[1]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[1]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[2]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[2]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[2]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[3]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[3]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[3]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[4]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[4]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[4]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[5]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[5]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[5]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[6]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[6]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[6]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
                <SectionTitle name={navIndex[7]}/>
                <section className="section-grid">
                    {
                        data.dishes[navIndex[7]].elements.map((el, index) => {
                            return <Card 
                            key={`${data.dishes[navIndex[7]].name}_${index}`}
                            title={el.name}
                            variants={el.variants}
                            description={el.description}
                            image={el.image}
                            price={el.price}
                            />
                        })
                    }
                </section>
            </main>
        </ContextProvider>
    )
}

export default App;