import { createGlobalStyle } from "styled-components";


const Main = createGlobalStyle`
:root{
    scroll-behavior: smooth;
    font-size: ${props => props.fontSize}%;
}

body{
    background-color: #141414;
    color: white;
    font-family: sans-serif;
    margin: 0 0 50px 0;

}

.section-grid{
    display: grid;
    gap: 10px;
    padding-inline: 10px;
}
`

export default Main;