import styled from "styled-components";

const BodyCard = styled.article`
    background-color: #1a1a1a;
    padding: 10px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 10px;

`
const ImageCard = styled.img`
    max-width: 100%;
    min-width: 100%;
    height: 100px;
    min-height: 100px;
    object-fit: cover;
    border-radius: 10px;
`

const TitleCard = styled.h3`
    color: #aaa;
    margin: 0;
    font-size: 22px;
    font-weight: normal;
`

const DescriptionCard = styled.p`
    color: #999;
    line-height: 140%;
    font-size: .875em;
`

const VariantsInCard = styled.p`
    margin: 0;
    padding: 10px;
    border-radius: 40px;
    border: 1px solid #666;
    color: #ddd;
    font-size: 13px;
    display: inline-block;
`

const VariantsContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`

export {
    BodyCard,
    TitleCard,
    ImageCard,
    DescriptionCard,
    VariantsInCard,
    VariantsContainer
}