import styled from 'styled-components';

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #000c;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
`

const CloseButton = styled.button`
    border: none;
    width: 150px;
    height: 50px;
    border-radius: 80px;
    border: 1px solid #ccc;
    color: #ccc;
    background: transparent;
    position: absolute;
    top: 50px;
    left: calc(50% - 75px);
    font-size: 14px;

`

const Image = styled.img`
    width: 100vw;
    height: auto;
`

export {
    Background,
    CloseButton,
    Image
}