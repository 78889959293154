import { HeaderContainer, HeaderLogo, HeaderTitle } from "./HeaderStyle";
import AccesibilityFont from "../AccesibilityFont";

const Header = () => {
    
    return (
        <HeaderContainer>
            <HeaderLogo src="./static/logo.svg" alt="U Sushi"/>
            <AccesibilityFont/>
        </HeaderContainer>
    )
}

export default Header;